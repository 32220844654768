import React, { lazy, Suspense, useContext } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import AuthContext from '../contexts/AuthContext';

const AuthRoute = lazy(() => import('../routes/AuthRoute'));
const AdminRoute = lazy(() => import('../routes/AdminRoute'));

function App() {
  const { user } = useContext(AuthContext);
  if (user === 'loading') return null;

  return (
    <BrowserRouter>
      <CssBaseline />
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/(login|signup|verify|forgot|newpass)" component={AuthRoute} />
          <Route path="/" component={AdminRoute} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
