import React, { useEffect, useState, createContext } from 'react';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import PropTypes from 'prop-types';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState('loading');

  const checkUser = () => {
    Auth.currentAuthenticatedUser()
      .then(res => {
        setUser(res);
      })
      .catch(err => {
        if (err === 'not authenticated') Auth.signIn('guest');
      });
  };

  Hub.onHubCapsule = () => {
    checkUser();
  };

  useEffect(() => {
    checkUser();
    Hub.listen('auth', Hub);
  }, []);

  const value = { user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider };
export default AuthContext;
